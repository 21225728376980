
/** @jsx jsx */

import React from "react"
import  { Link }  from "gatsby"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import {jsx, css} from '@emotion/react'
import Header from "./header"
import "./layout.css"
import "./popup/modal.css"

const breakpoints =[576]

const mq = breakpoints.map(
  bp => `@media(min-width: ${bp}px)`
)


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
   
      
        <main css={{
             display:`grid`,
              gridAutoRows:'1fr',
              width:`98vw`,
              height:`98vh`,
              border:"rgb(0 0 0 / 30%) 0px 8px 16px -8px inset",
              gridTemplateColumns:'repeat(6, 1fr)',
              
              [mq[0]]:{
                gridTemplateColumns:`repeat(10, 1fr)`
              },
             
       }}>
      <Header css={{
          
          [mq[0]]:{
            gridRow:`1`,
            gridColumn: '1/6',
            boxShadow:"rgb(0 0 0 / 30%) 0px 8px 16px -8px inset",
          },
      }}siteTitle={data.site.siteMetadata?.title || `Title`} />
      {children}</main>
      
        <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a> from <a href="https://zeitfuctory.de">zeitf*ctory </a>by <a href="https://www.gwki.de">beny </a>
          picture by <a href="https://wimmelfoto.de">Christian Richter (wimmelfoto.de) </a>  
        <Link to="/impressum">Impressum</Link>
        <br></br>
        <a href="https://www.instagram.com/limeriki_tofu/">Insta-Account</a>
        </footer>
      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
